import WarpElement from '@warp-ds/elements-core';
import { css, html } from 'lit';
import throttle from 'lodash/throttle.js';

// To reduce bundle size, import only the components and icons you need
// https://github.com/warp-ds/elements?tab=readme-ov-file#import-components
// https://warp-ds.github.io/tech-docs/components/icons/
import createHeartComponent from '@finn-no/favorite-heart-component';
import pulse from '@finn-no/pulse-sdk';
import { i18n } from '@lingui/core';
import {
	getPlacementList,
	placementHasIntermingle,
	AdvertisingSlotComponent,
	AdvertisingNativeSlotComponent
} from '@schibsted-nmp/advertising-companion';
import '@warp-ds/elements/components/button';
import '@warp-ds/elements/components/card';
import '@warp-ds/icons/elements/shipping-16';
import { customElement } from 'lit/decorators/custom-element.js';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { DisplayAdsPlacement, RecommendationItem, Recommendations, AdvertisingAd } from '../../server/types.js';

@customElement('frontpage-recommendations')
export class FrontpageRecommendations extends WarpElement {
	static styles = [
		...WarpElement.styles, // TODO: why doesn't pick up correct brand colours?
		css`
			.custom-label-style {
				background: linear-gradient(
					transparent,
					rgba(0, 0, 0, 0.04935) 13.71%,
					/* ... (rest of the gradient styles) */ rgba(0, 0, 0, 0.78731) 89.73%,
					rgba(0, 0, 0, 0.8)
				);
				width: 100%;
			}
			/** The line below is where the injected CSS goes, removing it means you get no CSS from the design system **/
			@css-placeholder-tJyxqs;
		`,
	];

	data!: Recommendations;
	newItems!: RecommendationItem[];
	url!: string;
	imgCdnUrl!: string;
	favoriteBaseUrl!: string;
	brand!: string;
	loginId!: string;
	nextPage = 0;
	canFetchMore = true;
	hasBottomReachedBeenTracked = false;
	isFetching = false;
	_displayAdsPlacement!: DisplayAdsPlacement[];

	private mountedAds = new Set<string>();
	private pendingAds: AdvertisingAd[] = [];

	static properties = {
		data: { type: Object, default: {} },
		imgCdnUrl: { type: String },
		favoriteBaseUrl: { type: String },
		newItems: { type: Object, default: [], attribute: false },
		url: { type: String },
		brand: { type: String },
		loginId: { type: String },
		canFetchMore: { type: Boolean, attribute: false, state: true },
		nextPage: { type: Number, attribute: false, default: 0 },
		_displayAdsPlacement: { type: Object, default: [], attribute: false },
	};

	wcRef =
		typeof document !== 'undefined'
			? document.querySelector('frontpage-recommendations')?.shadowRoot?.getElementById('recommendations')
			: undefined;

	get halfWayIndex() {
		return Math.ceil(this.data?.fetchMore.length / 2);
	}

	connectedCallback() {
		super.connectedCallback();

		const locale = this.locale || 'en';
		const messages = JSON.parse(this.getAttribute('translations') || '{}');

		i18n.load(locale, messages);
		i18n.activate(locale);

		// Insert Oikotie Ad in the specified location
		const oikotieAd = document.querySelector('frontpage-recommendations')?.shadowRoot?.querySelector('#oikotie-ad');
		oikotieAd?.insertAdjacentHTML(
			'afterbegin',
			`<iframe
							src="https://recommendations.asunnot.oikotie.fi/tori-aurora-front-page.html"
							class="relative isolate recommendation-ad card card--cardShadow s-bg"
							width="100%"
							height="100%"
						></iframe>`,
		);

		// Add infinite scroll event listener
		this.addInfiniteScroll();

		// Track view ad event
		this.trackViewEvent();
		window.addEventListener('scroll', this.trackViewEvent);

		// Track bottom reach
		window.addEventListener('scroll', this.handleBottomReach);
	}

	firstUpdated() {
		this._displayAdsPlacement = getPlacementList().filter(placementHasIntermingle);
	}

	/** Utility functions **/

	isItemCMS(item: RecommendationItem) {
		return item.type === 'CMS';
	}

	isItemAdvertising(item: RecommendationItem) {
		return item.type === 'NATIVE' || item.type === 'BANNER' || item.type === 'oikotie';
	}

	isInViewport = (el: Element) => {
		const { top, bottom } = el.getBoundingClientRect();
		const windowHeight = window.innerHeight || document.documentElement.clientHeight;

		const elementHeight = bottom - top;
		const halfHeight = elementHeight / 2;

		const topInView = top >= 0 && top < windowHeight;
		const bottomInView = bottom > 0 && bottom <= windowHeight;

		return (topInView && top + halfHeight <= windowHeight) || (bottomInView && bottom - halfHeight >= 0);
	};

	favoriteHeart = (item: RecommendationItem) => {
		const Heart = createHeartComponent({
			baseUri: this.favoriteBaseUrl,
			renderServerSide: true,
			userId: this.loginId,
			modalAttach: this.wcRef as HTMLDivElement,
		});

		setTimeout(() => {
			const heartRef = this.isItemCMS(item) ? null : this.shadowRoot?.querySelector(`#heart-${item.itemId}`);
			if (heartRef) {
				const root = createRoot(heartRef);
				root.render(
					<Heart
						variant="heart-on-image"
						itemType="Ad"
						locale={this.locale as 'en' | 'nb' | 'da' | 'fi'}
						itemId={parseInt(item.itemId)}
						isMobile={false}
						renderNumFavs={false}
					/>,
				);
			}
		}, 100);
	};

	// Infinite scroll & fetch more
	addInfiniteScroll = () => {
		window.addEventListener('scroll', this.handleInfiniteScroll);
	};
	handleInfiniteScroll = throttle(() => {
		const endOfPage =
			window.innerHeight + window.scrollY >=
			(this.shadowRoot?.getElementById('recommendations')?.offsetHeight || document.body.offsetHeight);

		if (endOfPage) {
			this.fetchMore();
		}

		if (this.nextPage === this.halfWayIndex || !this.canFetchMore) {
			this.removeInfiniteScroll();
		}
	}, 200);
	removeInfiniteScroll = () => {
		window.removeEventListener('scroll', this.handleInfiniteScroll);
	};

	fetchMore() {
		if (this.isFetching || !this.canFetchMore) return;

		this.isFetching = true;

		const url = new URL(`${this.url}/recommendations-proxy/${this.data?.fetchMore[this.nextPage]}`);
		if (this._displayAdsPlacement.length === 0) {
			this._displayAdsPlacement = getPlacementList().filter(placementHasIntermingle);
		}

		try {
			fetch(url)
				.then((response) => response.json())
				.then((data) => {
					if (this.nextPage + 1 > this.halfWayIndex) {
						this.addInfiniteScroll();
					}
					this.nextPage = this.nextPage + 1;
					this.canFetchMore = this.nextPage !== this.data?.fetchMore.length;
					this.newItems = data.items;
					this.data.items.push(...this.newItems);
					this.insertDisplayAds();
				})
				.catch((e) => {
					console.log(e);
				})
				.finally(() => {
					this.isFetching = false;
				});
		} catch (e) {
			console.log(e);
			this.isFetching = false;
		}
	}

	insertDisplayAds() {
		if (this._displayAdsPlacement) {
			this._displayAdsPlacement.forEach((ad: DisplayAdsPlacement) => {
				if (this.data.items.find((r) => r.itemId === ad.placementId)) return;

				if (this.data.items.length < ad.intermingle.grid) return;

				const gridPosition = ad.intermingle.grid - 1;

				const part1 = this.data.items.slice(0, gridPosition);
				const part2 = this.data.items.slice(gridPosition);
				const advt = { itemId: ad.placementId, type: 'BANNER' };
				this.data.items = [...part1, advt, ...part2];
			});
		}
	}
	_handleFetchMore() {
		this.fetchMore();
	}

	// Tracking
	trackViewEvent = () => {
		const items = this.data?.items;
		if (items) {
			items.forEach((item) => {
				const element = this.isItemAdvertising(item)
					? this.shadowRoot?.getElementById(item.itemId.replace(/:/g, '-'))
					: this.shadowRoot?.getElementById(`ad-${item.itemId}`);

				if (element && this.isInViewport(element) && !item.hasBeenViewed && item.tracking?.pulse) {
					item.hasBeenViewed = true;
					const trackingData = item.tracking?.pulse?.recommendation?.filter((event) => event.type === 'View')[0];

					pulse.trackEvent(
						{
							type: trackingData?.type,
							name: trackingData?.name,
							object: trackingData?.object,
							recommendation: this.data?.['tracking-context']?.recommendation,
						},
						{
							disableAddClassifiedAdToItems: true,
						},
					);
				}
			});
		}
	};
	_trackClickEvent(item: RecommendationItem) {
		if (item.tracking?.pulse) {
			const trackingData = item.tracking.pulse.recommendation.filter((event) => event.type === 'Click')[0];

			pulse.trackEvent(
				{
					intent: trackingData.intent,
					name: trackingData.name,
					object: trackingData.object,
					target: trackingData.target,
					type: trackingData.type,
					recommendation: this.data['tracking-context']?.recommendation,
				},
				{
					disableAddClassifiedAdToItems: true,
				},
			);
		}
	}

	handleBottomReach = () => {
		const bottom = document.querySelector('frontpage-recommendations')?.shadowRoot?.getElementById('bottom');
		if (bottom) {
			const reached = bottom.getBoundingClientRect().top <= window.innerHeight;

			if (reached && !this.canFetchMore && !this.hasBottomReachedBeenTracked) {
				this.hasBottomReachedBeenTracked = true;
				fetch(`${this.url}/tracking`);
			}
		}
	};

	showHistoryLink = () => {
		return this.data?.items.length > 0 && this.brand?.toUpperCase() === 'FINN' && this.data?.isPersonal;
	};
	showFetchMoreButton = () => {
		const isHalfway = this.nextPage === this.halfWayIndex;
		const hasMoreData = Array.isArray(this.data?.fetchMore) && this.data.fetchMore.length > 0;
		return hasMoreData && this.canFetchMore && isHalfway;
	};

	/** Rendering functions **/

	label = (item: RecommendationItem) => {
		if (item.shippingOption?.label) {
			return html`<span
				class="absolute top-0 left-0 py-4 px-8 inline-flex rounded-tl-8 rounded-br-4 text-12 s-bg-warning-subtle-hover"
			>
				<w-icon-shipping-16 class="mr-4"></w-icon-shipping-16>
				${item.shippingOption.label}
			</span>`;
		}

		if (item.overlay?.easyapply?.label) {
			return html`<span
				class="absolute top-0 left-0 py-4 px-8 inline-flex rounded-tl-8 rounded-br-4 text-12 s-bg-positive-subtle-hover s-text-rgb-positive-active"
			>
				${item.overlay?.easyapply.label}
			</span>`;
		}

		if (item.overlay?.p4p?.label) {
			return html`<span
				class="absolute top-0 left-0 py-4 px-8 inline-flex rounded-tl-8 rounded-br-4 text-12 s-bg-info-subtle-active"
			>
				${item.overlay.p4p.label}
			</span>`;
		}

		if (this.isItemCMS(item)) {
			return html`<span
				class="absolute top-0 left-0 py-4 px-8 inline-flex rounded-tl-8 rounded-br-4 text-12 s-bg-info-subtle-active"
			>
				${i18n.t({
					id: 'frontpage.ads.cms-article.badge.label',
					message: 'Useful article',
				})}
			</span>`;
		}
	};

	image = (item: RecommendationItem) => {
		const isLogo = item.image?.type === 'LOGO';
		const imageClass = isLogo
			? 'w-full h-full object-center object-scale-down m-auto sm:h-auto'
			: 'w-full h-full object-center object-cover m-auto';
		const imageSrc =
			item.image?.isAbsolute || item.image?.type === 'EXTERNAL'
				? item.image.url
				: `${this.imgCdnUrl}/${item.image?.url}`;

		return html`<div class="aspect-square overflow-hidden border rounded-t-8 s-bg hover:s-bg-hover active:s-bg-active">
			<img
				class="${imageClass}"
				src="${imageSrc}"
				alt="${i18n.t({ id: 'frontpage.recommendations.images.alt.text', message: 'Photo from ad' })}"
			/>
			${item.type?.toUpperCase() !== 'JOB'
				? html`<span>
						<div
							class="absolute bottom-0 font-bold s-text-inverted-subtle pt-[88px] pb-10 pl-10 pr-16 custom-label-style"
						>
							${item.label}
						</div>
					</span>`
				: ''}
		</div>`;
	};

	recommendationItem = (item: RecommendationItem) => {
		this.favoriteHeart(item);

		const href = this.isItemCMS(item) ? item.itemId : `/${item.itemId}`;

		return html`
			<w-card id=${`ad-${item.itemId}`}>
				${item.image ? this.image(item) : ''}
				<div class="text-s s-text-subtle mx-16 mt-8">
					<span>${item.location?.combined}</span>
				</div>
				<div class="mx-16 mt-6 mb-14">
					<h2 class="t4 mb-0 break-words" id=${item.itemId}>
						<a
							class="s-text hover:no-underline line-clamp"
							href=${href}
							id=${item.itemId}
							@click="${() => this._trackClickEvent(item)}"
						>
							<span class="absolute inset-0" aria-hidden="true"></span>
							${item.heading}
						</a>
					</h2>
					<div class="text-s s-text-subtle mt-8">
						${item.content?.company_name ? html`<div>${item.content?.company_name}</div>` : ''}
						${item.customLabel ? html`<div>${item.customLabel}</div>` : ''}
					</div>
				</div>
				${this.isItemCMS(item) ? '' : html`<div class="absolute top-8 right-8" id=${`heart-${item.itemId}`}></div>`}
				${this.label(item)}
			</w-card>
		`;
	};

	advertisingItem = (item: RecommendationItem) => {
		if (item.type === 'oikotie') {
			return this.oikotieAd();
		}
		
		const divId = item.itemId.replace(/:/g, '-');
		if (!this.mountedAds.has(divId)) {
			this.pendingAds.push({ adId: divId, adType: item.type });
		}

		return html`<div id="${divId}"></div>`;
	};

	protected updated(changedProperties: Map<string, unknown>): void {
		super.updated(changedProperties);

		// Mount any pending ads
		for (const pendingAd of this.pendingAds) {
			const adId = pendingAd.adId;
			const advertisingAdRef = this.shadowRoot?.querySelector(`#${CSS.escape(adId)}`);
			
			if (advertisingAdRef) {
				const item = this.data?.items.find((item) => item.itemId.replace(/:/g, '-') === adId);
				if (!item) continue;

				const root = createRoot(advertisingAdRef);
				const categories = {
					main_category: item.content?.main_category,
					sub_category: item.content?.sub_category,
					prod_category: item.content?.prod_category,
				};

				// Not optimal long term solution, will re-do after transition
				if (pendingAd.adType === 'NATIVE') {
					root.render(
						<AdvertisingNativeSlotComponent
							itemId={adId}
							categories={JSON.stringify(categories)}
							initialLayoutType={'grid'}
							webComponentName={'frontpage-recommendations'}
						/>,
					);
				}
				else {
					root.render(
						<AdvertisingSlotComponent
							itemId={adId}
							categories={JSON.stringify(categories)}
							initialLayoutType={'grid'}
							webComponentName={'frontpage-recommendations'}
						/>,
					);
				}

				this.mountedAds.add(adId);
				const adIndex = this.pendingAds.findIndex((ad) => ad.adId === adId);
				this.pendingAds.splice(adIndex);
			}
		}
	}

	recommendationsTitle = () => {
		return this.data?.isPersonal
			? i18n.t({
					id: 'frontpage.recommendations.section.title',
					message: 'Personalized recommendations',
				})
			: i18n.t({
					id: 'frontpage.popular.section.title',
					message: 'Popular ads',
				});
	};

	oikotieAd = () => {
		return html`<div
			class="cursor-pointer overflow-hidden relative transition-all group rounded-8 s-surface-elevated-200 hover:s-surface-elevated-200-hover active:s-surface-elevated-200-active  s-bg hover:s-bg-hover active:s-bg-active s-border hover:s-border-hover active:s-border-active"
			id="oikotie-ad"
		></div>`;
	};

	render() {
		return this.data?.items?.length > 0 ? html`
			<div id="recommendations">
				<section>
					<div class="flex flex-row items-center my-16">
						<h1 class="text-ml mb-4">${this.recommendationsTitle()}</h1>
						${this.showHistoryLink()
							? html`<a href="/anbefalinger/historikk" class="link text-14 px-8">
									${i18n.t({
										id: 'frontpage.recommendations.history.link.text',
										message: 'Why we recommend these ads?',
									})}
								</a>`
							: ''}
					</div>
					<div class="grid grid-cols-2 md:grid-cols-3 gap-16 mb-16 grid-flow-row-dense">
						${this.data?.items?.map((item) =>this.isItemAdvertising(item) ? this.advertisingItem(item) : this.recommendationItem(item))}
					</div>
					${this.showFetchMoreButton()
						? html`
								<div class="text-center mb-16">
									<w-button variant="primary" @click="${this._handleFetchMore}"
										>${i18n.t({
											id: 'frontpage.recommendations.fetch-more-button.label',
											message: 'See more recommendations',
										})}</w-button
									>
								</div>
							`
						: ''}
					<span
						class="hidden col-span-5 sm:col-span-3 focus:[--w-outline-offset:-2px] sm:col-span-2 md:grid-cols-2 gap-16 flex-row object-cover text-left text-ellipsis line-clamp-2 mt-24 pb-24 lt-sm:min-w-full"
						id="bottom"
					></span>
				</section>
			</div>
		`
		: 
		html``;
	}
}
